.buyer-section{
    width: 1200px;
    /* display: "inline-block";  */
    /* margin: 20px 50px 0px 50px; */
}
.uploder-section{
    /* width: 1200px; */
    /* display: "inline-block";  */
    /* margin: 30px 0px; */
}
.payment-title{
    margin: 20px 0;
}