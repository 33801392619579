/* profile avator  */
.user-profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.admin-name {
  display: flex;
  flex-direction: column;
  margin: 0;
}

/* logo */
.LogoImgs {
  width: 120px;
  /* padding: 0; */
  margin: 0;
}
.logo-container {
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 1;
}

.logo-container-main {
  width: 100vw;
  height: 78px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 2.5rem;
  box-shadow: 0px -10px 20px grey;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between; /* align items with space-between */
  list-style: none;
  width: 200px;
  padding: 0;
  margin: 0;
}

.nav-links li {
  display: flex;
  align-items: center;
}

.nav-links li a {
  color: black;
  text-decoration: none; /* remove underline from anchor tags */
}
.person-role {
  color: dodgerblue;
}

/* For Profile pic and role  */
.profile-details {
  line-height: 8px !important;
}
/* For Collapse  */
.ant-layout-sider-trigger {
  background-color: #cacaca !important; /* Background color */
  color: #292929 !important; /* Text color */
}
