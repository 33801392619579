.slider-container {
  display: flex;
}
.slider-menu {
  margin-right: 20px; /* Adjust as needed */
  width: 250px;
}

.ant-menu-item-selected {
  background-color: #02b9f5 !important;
  color: white !important;
  border-radius: 0px 120px 120px 0px !important;
}

.ant-menu-item-selected .anticon {
  color: white !important;
}

.ant-menu-item-selected img {
  filter: brightness(0) invert(1);
  color: white;
}

.ant-menu-item-selected:hover {
  color: white !important;
}

.ant-menu-item:hover {
  /* background-color: #e5f8fe !important; */
  border-radius: 0 120px 120px 0 !important;
}
