.buyer-section{
    width: auto;
    /* display: "inline-block";  */
    /* margin: 20px 50px 0px 80px; */
}
.uploder-section{
    /* width: 1200px; */
    
    display: "inline-block"; 
    /* margin: 30px 80px; */
}
.payment-title{
    margin-bottom: 16px;
}
