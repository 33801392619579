.AddUser-bg-color {
  width: 700px;
  background-color: white;
  display: inline-block;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgb(204, 204, 204);
  /* box-shadow:  4px 8px 15px  rgb(221, 221, 221);  */
}

/* outer grid container  */
.grid-containers {
  display: grid;
  /* grid-template-columns:  repeat( 2, 4fr ); */
  grid-template-columns: 515px 120px;
  justify-content: space-between;
}

/* inner grid container  */
.grid-container {
  display: grid;
  /* grid-template-rows: repeat(auto-fit, minmax(100px ,20px)); */
  grid-template-columns: repeat(2, 2fr);
  /* width: auto; */
  grid-gap: 20px 10px;
  /* justify-content: space-between; */
  /* align-content: center; */
  /* grid-template-columns:  repeat(auto-fit, minmax(100px, 2fr)) */
}
.btn-span {
  grid-column: span 2;
}
.checkbox-para {
  font-size: 10px;
  color: gray;
}
.checkbox-text-color {
  color: gray;
}
.border-circle {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 15px;
  padding: 6px 15px;
}
